import tshirtblack from "../../images/tshirtblack.png";
import tshirtblue from "../../images/tshirtblue.png";
import tshirtwhite from "../../images/tshirtwhite.png";

export const SliderData = [
  {
    image: tshirtwhite,
  },
  {
    image: tshirtblue,
  },
  {
    image: tshirtblack,
  },
];
